@tailwind base;
@tailwind components;
@tailwind utilities;
button[disabled=disabled], button:disabled {
  cursor: not-allowed;
}

.input-2{
  position: relative;
}
.input-2 i{
  cursor: pointer;
  position: absolute;
  top: 17px;
  right:  10px;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}